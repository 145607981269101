/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { DarkText, FlowButton, SIZE_PRESETS, TextInput, TEXT_INPUT_TYPES } from '../components/flowComponents';
import { EntryPage } from './EntryPage';
import firebase from 'firebase/app';
import { useQuery } from '../utils';
import { BANNER_STYLES } from '../components/banners';
import { useHistory } from 'react-router';
import { translateAuthErrorCodeToMessage } from '../components/utils/auth';
import { Link } from 'react-router-dom';
import { baseURL } from '../firebase';
import { MainLoading } from '../commonLayout/MainLoading';
import { useVerifyUserEmail } from '../fetch/endpoints';
import { toastSuccess, toastError } from '../components/utils/toaster';

const firebaseAuth = firebase.auth()


const modeToActionTextAndLink = {
  resetPassword: ['Reset Password', '/reset-password'],
  verifyEmail: ['Verify Email', '/login'],
  recoverEmail: ['Recover Email', '/login'],
}

const getActionTextAndLink = (mode) => {
  return modeToActionTextAndLink[mode] || ['Login', '/login']
}

export const EmailAuthAction = () => {
  const query = useQuery()
  const queryParams = ['mode', 'oobCode', 'continueUrl']
  const [mode, actionCode, continueUrl] = queryParams.map(paramName => query.get(paramName))
  const redirect = continueUrl !== null ? continueUrl.slice(baseURL.length) : null
  const { performFetch: verifyUserEmail } = useVerifyUserEmail()

  const [email, setEmail] = useState(null)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  useEffect(() => {
    handleAuthManagementAction()
  }, [mode, actionCode, continueUrl])

  const history = useHistory()
  const [actionText, actionLink] = getActionTextAndLink(mode)
  const redirectFromInvalidLink = () => history.push(`${actionLink}?error=invalid-link`)

  async function handleAuthManagementAction() {
    setInProgress(true)
    setError(null)
    setSuccess(false)

    try {
      switch (mode) {
        case 'resetPassword':
          await handleResetPasswordAction()
          break
        case 'verifyEmail':
          await handleVerifyEmailAction()
          break
        case 'recoverEmail':
          await handleRecoverEmailAction()
          break
        default:
          redirectFromInvalidLink()
      }
    } catch (error) {
      toastError({ message: translateAuthErrorCodeToMessage(error.code) })
      redirectFromInvalidLink()
    } finally {
      setInProgress(false)
    }
  }

  async function handleResetPasswordAction() {
    const accountEmail = await firebaseAuth.verifyPasswordResetCode(actionCode)
    setEmail(accountEmail)
  }

  async function handleVerifyEmailAction() {
    const result = await firebaseAuth.applyActionCode(actionCode)
    setEmail(firebaseAuth.currentUser.email)
    const { success } = await verifyUserEmail({ email: firebaseAuth.currentUser.email })
    if (success) {
      setSuccess(true)
      toastSuccess({ message: 'Email verified successfully' })
    } else {
      toastError({ message: "Failed to verify email. Please check the link and try again." })
      redirectFromInvalidLink()
    }
  }

  async function handleRecoverEmailAction() {
    const email = await firebaseAuth.checkActionCode(actionCode)
    setEmail(email)
  }

  if (inProgress) {
    return <MainLoading />
  }


  if (success) {
    return <SuccessScreen message="Email verified successfully" redirect={redirect} />
  }

  if (error) {
    return <ErrorScreen error={error} actionText={actionText} actionLink={actionLink} />
  }

  if (email) {
    return <NewPasswordInput error={error} email={email} mode={mode} actionCode={actionCode} setError={setError} />
  }

  return <MainLoading />
}

const NewPasswordInput = ({ error, email, mode, actionCode, setError }) => {
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')
  const [resetInProgress, setResetInProgress] = useState(false)
  const [passwordSuccessfullyReset, setPasswordSuccessfullyReset] = useState(false)

  const disabled = !(newPassword.length > 0 && newPasswordConfirmation.length > 0)

  const onSubmit = async (event) => {
    event.preventDefault()
    if (newPassword !== newPasswordConfirmation) {
      setError("Passwords entered don't match.")
      return
    }

    setResetInProgress(true)

    try {
      if (mode === 'resetPassword') {
        await firebaseAuth.confirmPasswordReset(actionCode, newPassword)
        setPasswordSuccessfullyReset(true)
      }
    } catch (error) {
      setError(translateAuthErrorCodeToMessage(error.code))
    }
    setResetInProgress(false)
  }

  if (!passwordSuccessfullyReset) {
    return (
      <EntryPage bannerContent={error} bannerStyle={BANNER_STYLES.error}>
        <div>
          <form onSubmit={onSubmit}>
            <h2 css={css`margin-bottom: 28px;`}>{mode === 'resetPassword' ? 'Password Reset' : 'Recover Email'}</h2>
            <DarkText customCss={css`margin-bottom: 20px;`}>
              {mode === 'resetPassword' ? (
                <span>
                  Password reset for <span css={css`font-weight: 700;`}>{email}</span>
                </span>
              ) : (
                <span>
                  Recover email for <span css={css`font-weight: 700;`}>{email}</span>
                </span>
              )}
            </DarkText>
            <TextInput
              sizePreset={SIZE_PRESETS.CHUNKY}
              customCss={css`margin-bottom: 8px;`}
              placeholder="Enter new password"
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
              label={'New Password'}
              type={TEXT_INPUT_TYPES.PASSWORD}
            />
            <TextInput
              sizePreset={SIZE_PRESETS.CHUNKY}
              customCss={css`margin-bottom: 16px;`}
              placeholder="Confirm password"
              value={newPasswordConfirmation}
              onChange={(event) => setNewPasswordConfirmation(event.target.value)}
              label={'Confirm New Password'}
              type={TEXT_INPUT_TYPES.PASSWORD}
            />
            <FlowButton
              fillAvailableWidth
              sizePreset={SIZE_PRESETS.CHUNKY}
              disabled={disabled}
              loading={resetInProgress}
              onClick={onSubmit}
              type={'submit'}
            >
              {mode === 'resetPassword' ? 'Reset Password' : 'Recover Email'}
            </FlowButton>
          </form>
        </div>
      </EntryPage>
    )
  } else {
    return <SuccessScreen message={mode === 'resetPassword' ? 'Password reset successfully' : 'Email recovered successfully'} />
  }
}

const SuccessScreen = ({ message, redirect }) => {
  return (
    <EntryPage>
      <h2 css={css`margin-bottom: 28px;`}>{message}</h2>
      <DarkText customCss={css`margin-bottom: 36px;`}>You can now log in with your new credentials</DarkText>
      <Link to={`/login${redirect !== null ? `?redirect=${redirect}` : ''}`}>
        <FlowButton sizePreset={SIZE_PRESETS.CHUNKY} fillAvailableWidth>
          Log In
        </FlowButton>
      </Link>
    </EntryPage>
  )
}

const ErrorScreen = ({ error, actionLink, actionText }) => {
  return (
    <EntryPage bannerContent={error} bannerStyle={BANNER_STYLES.error}>
      <Link to={actionLink}>
        <FlowButton sizePreset={SIZE_PRESETS.CHUNKY} fillAvailableWidth>
          {actionText}
        </FlowButton>
      </Link>
    </EntryPage>
  )
}